import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

const Button = (props) => {
  const settings = props.block ? props.block.settings : null;

  if (settings) {
    return (
      <Link
        to={settings.url}
        className={"Button " + settings.class}
        id={settings.id}
      >
        {settings.text}
      </Link>
    );
  } else {
    return (
      <Link to={props.url} className={"Button " + props.type}>
        {props.children}
      </Link>
    );
  }
};

export default Button;
