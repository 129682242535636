import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navi = (props) => {
  const [state, setState] = useState({
    pagedata: props.block,
    navi: "",
  });

  const toggleNavi = () => {
    if (state.navi === " active") {
      setState((prev) => ({
        ...prev,
        navi: "",
      }));
    } else {
      setState((prev) => ({
        ...prev,
        navi: " active",
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      navi: "",
      pagedata: props.block,
    }));
  }, [props]);

  const onlyMainNav = (data, key) => {
    if (data.Hauptnavigation) {
      return (
        <li key={key}>
          <Link to={"/" + data.Titel_slug}>{data.Titel}</Link>
        </li>
      );
    } else {
      return null;
    }
  };

  return (
    <nav className="naviBlock">
      <button onClick={() => toggleNavi()}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul className={"Navi" + state.navi}>
        {state.pagedata &&
          state.pagedata.map((item, key) => onlyMainNav(item, key))}
      </ul>
    </nav>
  );
};

export default Navi;
