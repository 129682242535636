import React, { useState, createContext } from 'react'

export const PageContext = createContext()

export const PageProvider = ({ children }) => {

    const [fullView, setFullView] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")


    const value = {
        fullView,
        setFullView,
        searchTerm,
        setSearchTerm
    }
    return <PageContext.Provider value={value}>{children}</PageContext.Provider>

}