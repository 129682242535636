import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom'
import './zoom.css';

import { APIURL, IMGTOKEN } from "../../utils/apiData";

const Picture = (props) => {
  const source = props.src;
  const alt = props.alt;
  const apiImg = APIURL + "/api/cockpit/image?token=" + IMGTOKEN + "&o=true&q=70&src=" + source;
  const imgWidth = [
    { "img": "300", "media": "576", "minMax": "max" },
    { "img": "400", "media": "768", "minMax": "max" },
    { "img": "500", "media": "992", "minMax": "max" },
    { "img": "600", "media": "993", "minMax": "min" }
  ];

  

  let src;

  if (source.indexOf('/') === 0) {
    src = apiImg + "&w=600";
  } else {
    src = source;
  }

  const SourceSet = () => {
    if (source.indexOf('/') === 0) {

      return imgWidth.map((item, key) => (
        <source srcSet={apiImg + "&w=" + item.img} media={"(" + item.minMax + "-width: " + item.media + "px"} key={key} />
      ))
    } else {
      return <source srcSet={source} media="(min-width: 0px"></source>
    }


  }


  if (props.zoom) {
    return (<InnerImageZoom src={src} imgAttributes={{alt:alt}} />)
  } else {
    return (
      <picture className="Picture">
        <SourceSet />
        <img
          className={props.class}
          src={src}
          alt={alt}
        />
      </picture>
    );
  }


};

export default Picture;
