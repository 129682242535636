/* eslint-disable */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
// import ReactPiwik from "react-piwik";
import "./App.scss";

import Page from "./Page";

const App = () => {
  // const piwik = new ReactPiwik({
  //   url: "www.smueller-design.de/matomo/",
  //   siteId: 1,
  // });
  // ReactPiwik.push(["requireCookieConsent"]);
  // ReactPiwik.push(["trackPageView"]);
  // ReactPiwik.push(["enableHeartBeatTimer"]);
  // ReactPiwik.push(["enableLinkTracking"]);
  // let history = useHistory();
  // useEffect(() => {
  //   const trackAtConnect = false;
  //   if (history) {
  //     piwik.connectToHistory(history, trackAtConnect);
  //   }
  //  }, [history, piwik]);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/">
            <Page />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
