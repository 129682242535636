import React from 'react';
import Picture from '../Picture/Picture';
import './Gallery.scss';

const Gallery = (props) => {
    const settings = props.block.settings;
    if(props.block.settings.gallery){
        const elementArr = props.block.settings.gallery;
        const GalleryElement = () =>{
            if(elementArr.length > 0){
                return elementArr.map((item, index) => (          
                    <div className="GalleryImage" key={index} >
                        <Picture src={item.path} alt={item.meta.title} />
                        <span className="title">{item.meta.title}</span>
                    </div>
                ))
            }else{
                return ( 
                    <span>Keine Bilder vorhanden</span>
                )
            }
        }

        return (
            <div className={"Gallery "+settings.class}
            id={settings.id}>
                <GalleryElement />
            </div>
        );
    }else{
        return (
            <div className={"Gallery "+settings.class}
            id={settings.id}>
               No Gallery set!
            </div>
          );
    }
};

export default Gallery;
