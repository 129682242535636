import React, { useState, useEffect, useContext } from "react";
import { PageContext } from '../../utils/context'


const ProductSearch = (block) => {
    let result = [];
    const { searchTerm, setSearchTerm } = useContext(PageContext)
    const [search, setSearch] = useState("");
    const [products, setProducts] = useState("");
    const [startSearch, setStartSearch] = useState(false);

    useEffect(() => {
        setProducts(block.products)
    }, [block.products]);

    useEffect(() => {
        if (startSearch) {
            setSearchTerm(search)
        }
    }, [startSearch, search, setSearchTerm]);

    return (
        <form className="ProductSearch">
            <div className="input">
                <input type="text" placeholder="Suche" onChange={
                    (e) => {
                        setSearch(e.target.value);
                    }
                } value={search} />
                <button type="submit">
                    <svg enable-background="new 0 0 70 70" height="70px" id="Icons" version="1.1" viewBox="0 0 70 70" width="70px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><path d="M51.957,49.129l-8.713-8.713c1.75-2.337,2.799-5.229,2.799-8.373c0-7.732-6.268-14-14-14s-14,6.268-14,14s6.268,14,14,14  c3.144,0,6.036-1.049,8.373-2.799l8.713,8.713L51.957,49.129z M22.043,32.043c0-5.514,4.486-10,10-10c5.514,0,10,4.486,10,10  c0,5.514-4.486,10-10,10C26.529,42.043,22.043,37.557,22.043,32.043z" /></svg>
                </button>
            </div>
            {search && search.length > 2 &&
                <div className="results">
                    <ul>
                        {products?.entries?.map((item, key) => {
                            if (item.name.toLowerCase().includes(search.toLowerCase())) {
                                if (!result.includes(item.name)) {
                                    result.push(item.name);
                                    return (<li key={key} onClick={(e) => {
                                        e.preventDefault();
                                        setSearch(item.name);
                                        setStartSearch(true);
                                    }}>

                                        {item.name}

                                    </li>)
                                }
                            }
                        })}
                    </ul>
                </div>}
            {searchTerm && <span className="searchTerm" onClick={() => {
                setSearchTerm("");
                setSearch(false);
            }}>{searchTerm}</span>}
        </form>
    );
}

export default ProductSearch;