import React from 'react';
import './Text.scss';

const Text = (props) => {
    const settings = props.block.settings;
  return (
    <div
      className={"Text "+settings.class} id={settings.id}
      dangerouslySetInnerHTML={{ __html: settings.text }}
    ></div>
  );
};

export default Text;
