import React from 'react';
import { Link } from 'react-router-dom';
import './ShoppingCart.scss';

import Cart from '../../statics/img/cart.svg';

const ShoppingCart = (props) => {
  return (
      <div className="Warenkorb" >
        <Link to="/warenkorb" className="icon"><img src={Cart} className="cart" alt="Warenkorb" />
            <span className="indicator">{props.number}</span>
        </Link>
      </div>
  );
};

export default ShoppingCart;

