import React from "react";
import ReactDOM from "react-dom";
import "./utils/fonts.scss";
import "./utils/breakpoints.scss";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { PageProvider } from './utils/context'


const instance = createInstance({
  urlBase: "//www.smueller-design.de/matomo/",
  siteId: 1,
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setDoNotTrack: false,
  },
});

ReactDOM.render(
  <PageProvider>
    <React.StrictMode>
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </React.StrictMode>
  </PageProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
