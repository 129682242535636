import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Picture from "../Picture/Picture";
import "./Products.scss";
import ProductSearch from "./ProductSearch";
import { PageContext } from '../../utils/context'


import { ShopSettings } from "../../Page";

import { APIURL, TOKEN } from "../../utils/apiData";

const Products = (props) => {
  const { searchTerm } = useContext(PageContext)

  const [products, setProducts] = useState("");
  const [kategorien, setKategorien] = useState("");
  const [givenCategories, setGivenCategories] = useState([]);
  const [sortedBy, setSortedBy] = useState("");
  const [category, setCategory] = useState("");
  const settings = props.block.settings;
  const thisSettings = useContext(ShopSettings);
  const active = thisSettings?.active;
  const location = useLocation();

  useEffect(() => {
    const abortController = new AbortController();
    const getProducts = async () => {
      let collection = settings.collection;
      localStorage.setItem("products", collection);

      let filter = "";
      // if (location.hash) {
      //   filter = { "category.display": location.hash.substring(1) };
      // }

      if (location.hash) {
        setCategory(location.hash.substring(1));
      }

      try {
        const result = await fetch(
          APIURL + "/api/collections/get/" + collection + "?token=" + TOKEN,
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              filter,
            }),
            signal: abortController.signal,
          }
        );
        const data = await result.json();
        setProducts(data);
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error;
      }
    };

    const getCategories = async () => {
      try {
        const result = await fetch(
          APIURL + "/api/collections/get/kategorien?token=" + TOKEN,
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
            signal: abortController.signal,
          }
        );
        const data = await result.json();
        setKategorien(data);
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error;
      }
    };

    if (active) {
      getProducts();
      getCategories();
    }
    return () => abortController.abort();
  }, [active, settings.collection, location.hash]);

  useEffect(() => {
    if (products && products.entries) {
      let categories = [];
      products.entries.map((item) => {
        if (item.category) {
          if (!categories.includes(item.category.display)) {
            categories.push(item.category.display);
          }
        }
        return null;
      });
      setGivenCategories(categories);
    }

  }, [products]);

  useEffect(() => {
    if (sessionStorage.getItem("scrollPosition")) {
      window.scrollTo(0, sessionStorage.getItem("scrollPosition"));
      //check current scroll position of browser. if it is at the position set in sessionStorage, remove sessionStorage
      if (window.scrollY === parseInt(sessionStorage.getItem("scrollPosition"))) {
        sessionStorage.removeItem("scrollPosition");
      }
    }
  }, [products]);

  const handleClick = (e) => {
    sessionStorage.setItem("scrollPosition", window.scrollY);
  };

  const Kategorien = () => {
    if (active) {
      return (
        <>
          <h5>Kategorien</h5>
          <ul>
            <li className={!location.hash ? "active" : undefined}>
              <Link to="/produkte">Alle</Link>
            </li>
            {kategorien?.entries?.map((item, key) => {
              if (givenCategories.includes(item.name_slug)) {
                return (<li
                  key={key}
                  className={
                    location.hash && location.hash === "#" + item.name_slug
                      ? "active"
                      : ""
                  }
                >
                  <Link to={"/produkte#" + item.name_slug}>{item.name}</Link>
                </li>)
              }
            })}
          </ul>
        </>
      );
    } else {
      return null;
    }
  };

  const ProductOverview = ({category}) => {
    if (active) {

      if (searchTerm) {
        let filtered = products.entries.filter((item) => {
          return item.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
        return filtered.map((item, key) => productRender(item, key));
      } else if (category) {
        let filtered = products.entries.filter((item) => {
          return item.category.display === category
        })
        return filtered.map((item, key) => productRender(item, key));
       
      }else {
        return products.entries.map((item, key) => productRender(item, key));

      }

    } else {
      return null;
    }
  };

  const productRender = (item, key) => {
    if (!item.verkauft) {
      return (
        <Link
          className="item"
          key={key}
          to={"/produkt-detail/" + item.name_slug + "/" + item.number}
          onClick={handleClick}
        >
          <div className="image">
            <Picture src={item.pictures[0].path} alt={item.pictures[0]?.meta?.title || "Produktbild"} />
          </div>
          <div className="name">{item.name}</div>
          <div className="price">{parseFloat(item.price).toFixed(2)} € *</div>
        </Link>
      );
    } else {
      return null;
    }
  };

  const Sortierung = () => {
    if (active) {
      return (
        <div className="sort">
          <ul>
            <li onClick={() => {
              if (!sortedBy || sortedBy !== "dateDown") {
                // sort by newest
                let sorted = products.entries.sort((a, b) => {
                  return new Date(b._created) - new Date(a._created);
                });
                setProducts({ entries: sorted });
                setSortedBy("dateDown");
              } else if (sortedBy === "dateDown") {
                // sort by oldest
                let sorted = products.entries.sort((a, b) => {
                  return new Date(a._created) - new Date(b._created);
                });
                setProducts({ entries: sorted });
                setSortedBy("dateUp");
              }
            }}>
              <span> &#9652; </span>Neueste<span> &#9662; </span>
            </li>
            <li onClick={() => {
              if (!sortedBy || sortedBy !== "priceDown") {
                // sort by price
                let sorted = products.entries.sort((a, b) => {
                  return a.price - b.price;
                });
                setProducts({ entries: sorted });
                setSortedBy("priceDown");
              } else if (sortedBy === "priceDown") {
                // sort by price
                let sorted = products.entries.sort((a, b) => {
                  return b.price - a.price;
                });
                setProducts({ entries: sorted });
                setSortedBy("priceUp");
              }

            }}>
              <span> &#9652; </span>Preis<span> &#9662; </span>
            </li>
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }



  return (
    <div className={"Products " + settings.class} id={settings.id}>
      <ProductSearch products={products} />
      <Sortierung />
      <div className="productOverview">

        <div className="kategorien">
          <Kategorien />
        </div>
        <div className="produktListe">
          {products && <ProductOverview key={products} category={category} />}
        </div>
      </div>

      {!active && <div>Der Shop ist zur Zeit nicht verfügbar.</div>}
      <div className="rechtliches">
        * Endpreis zzgl. Versandkosten, keine Ausweisung der Mehrwertsteuer
        gemäß § 19 UStG
      </div>
    </div>
  );
};

export default Products;
